import React from 'react'

import Heading from '../Heading'
import Paragraph from '../Paragraph'
import {
  HeadingContainer,
  HeadingStyled,
  GridItem,
  GridItemsContainer,
  GridItemHeadingContainer,
  GridItemIconContainer,
} from './styled'

import AbImMonatImage from '../../../static/images/icons/ab-im-monat.svg'
import MonatlichKuendigenImage from '../../../static/images/icons/monatlich-kuendigen.svg'
import SuperFlexibelImage from '../../../static/images/icons/super-flexibel.svg'
import NagelneuOderGebrauchtImage from '../../../static/images/icons/nagelneu-oder-gebraucht.svg'
import KostenlosGeliefertImage from '../../../static/images/icons/kostenlos-geliefert.svg'
import FullServiceImage from '../../../static/images/icons/full-service.svg'

const advantageItems = [
  {
    icon: AbImMonatImage,
    header: 'Angenehmer Mietpreis',
    description: 'Ab 10€ monatlich.',
  },
  {
    icon: MonatlichKuendigenImage,
    header: 'Unkomplizierte Kündigung',
    description: 'Monatlich kündbar nach nur drei Monaten.',
  },
  {
    icon: SuperFlexibelImage,
    header: 'Bleib flexibel',
    description: 'Dein Kind wächst - die nächste Größe ist direkt verfügbar.',
  },
  {
    icon: NagelneuOderGebrauchtImage,
    header: 'Nachhaltiges Prinzip',
    description:
      'Aufbereitete Gebrauchtmodelle auswählbar.',
  },
  {
    icon: KostenlosGeliefertImage,
    header: 'Kostenfreie Lieferung',
    description:
      'Beim ersten, beim Wechsel auf die nächste Größe und beim Rückversand.',
  },
  {
    icon: FullServiceImage,
    header: 'Service inklusive',
    description: 'Bei Defekten und Problemen kümmern wir uns.',
  },
]

const SectionAdvantages = () => (
  <div>
    <HeadingContainer>
      <HeadingStyled level={2}>
        Deine Vorteile
      </HeadingStyled>
    </HeadingContainer>

    <GridItemsContainer>
      {advantageItems.map(({ icon: Icon, header, description }) => (
        <GridItem key={header}>
          <GridItemIconContainer>
            <Icon />
          </GridItemIconContainer>

          <GridItemHeadingContainer>
            <Heading level={3} style={{ fontSize: '1.5rem' }}>
              {header}
            </Heading>
          </GridItemHeadingContainer>

          <Paragraph>{description}</Paragraph>
        </GridItem>
      ))}
    </GridItemsContainer>
  </div>
)

export default SectionAdvantages
