import React from 'react'
import theme from '../../../config/theme'
import Columns from '../Columns'
import Column from '../Column'
import ListItem from './ListItem'
import Mark, { markImages } from '../Mark'
import {
  Container,
  HeadingStyled,
  ParagraphStyled,
  ButtonStyled,
  LeftColumnContent,
  RightColumnContent,
} from './styled'

const processDataList = [
  {
    header: 'Bestellen',
    description:
      'Wähle das Modell, dass zu euch und den Bedürfnissen deines Kindes passt. Wir liefern es dir kostenfrei zum Wunschtermin!',
  },
  {
    header: 'Nutzen',
    description:
      'Nutze deinen Kinderwagen oder dein Kinderfahrrad so lange ihr es braucht. Geht im Alltag etwas kaputt, reparieren wir es innerhalb von 24 Stunden.',
  },
  {
    header: 'Zurückschicken',
    description:
      'Dein Kind ist rausgewachsen, ihr braucht ein neues Modell oder wollt das Abo beenden? Gib uns Bescheid und schicke deinen Kinderwagen oder dein Fahrrad kostenfrei an uns zurück.',
  },
]

const SectionProcess = () => (
  <Container>
    <Columns>
      <Column size={6}>
        <LeftColumnContent>
          <HeadingStyled level={2}>
            … und so{' '}
            <Mark
              image={markImages.yellowNormal}
              style={{
                backgroundPosition: '0 100%',
                backgroundSize: '100%',
                color: theme.palette.black[1],
              }}
            >
              kinderleicht
            </Mark>{' '}
            funktioniert StrollMe.
          </HeadingStyled>

          <ParagraphStyled>
            Es gibt so viel zu erleben mit deinem Kind! Genieße die Zeit und beschäftige dich
            nicht zu lange mit der Auswahl deines Kinderwagens oder Kinderfahrrads. Wir
            machen es dir deshalb so einfach, wie möglich.
          </ParagraphStyled>

          <ButtonStyled type="full" to="/produkte">
            Zu den Kinderwagen &amp; Fahrrädern
          </ButtonStyled>
        </LeftColumnContent>
      </Column>

      <Column size={6}>
        <RightColumnContent>
          {processDataList.map(({ header, description }, index) => (
            <ListItem key={header} counter={index + 1} header={header} description={description} />
          ))}
        </RightColumnContent>
      </Column>
    </Columns>
  </Container>
)

export default SectionProcess
