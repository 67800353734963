/* eslint-disable react/prop-types */
// ^ this is an absolute exception! only necessary because
// eslint wrongly picks up .order and .map as props
import * as React from 'react'
import styled from 'styled-components'
import { theme, palette } from 'styled-tools'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import themeConf from '../config/theme'

import Page from '../components/layouts/Page'
import Heading from '../components/parts/Heading'
import Container from '../components/parts/Container'
import Paragraph from '../components/parts/Paragraph'
import Button from '../components/parts/Button'
import MainProductList from '../components/parts/ProductList/MainList'
import Mark, { markImages } from '../components/parts/Mark'
import Columns from '../components/parts/Columns'
import Column from '../components/parts/Column'

import SectionAdvantages from '../components/parts/SectionAdvantages'
import { HeadingStyled } from '../components/parts/SectionAdvantages/styled'
import SectionProcess from '../components/parts/SectionProcess'
import SectionCircularEconomy from '../components/parts/SectionCircularEconomy'

import StoneBg from '../components/parts/StoneBg'
import CircularEconomyBg from '../components/parts/CircularEconomyBg'
import Collapsible from '../components/parts/Collapsible'
import Press from '../components/parts/Press'
import Pattern from '../components/parts/Pattern'
import background from '../static/images/february-shooting/strollme_shooting_Feb_21-203_m.jpg'
import SvgPatternSpots from '../static/images/patterns/dots.svg'

const MainHeadingStyled = styled(Heading)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 2rem;
  font-family: ${theme('fonts.libreBaskerville')};
  line-height: 1.2;
  letter-spacing: 1.8px;
  ${theme('media.smUp')} {
    margin-right: -31%;
    margin-left: auto;
    font-size: 3rem;
  }
  & ~ p {
    ${theme('media.smUp')} {
      margin-right: -31%;
      margin-bottom: 3rem;
      font-size: 1.3rem;
    }
  }
`

const ParagraphStyled = styled(Paragraph)`
  margin: 2rem 0;
  color: ${palette('black', 1)};
  font-weight: 700;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 1.5;

  ${theme('media.smDown')} {
    font-size: 1rem;
  }
`

const FAQContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 11rem;
`

const FAQHeading = styled(Heading)`
  margin-bottom: 4.5rem;
  font-weight: bold;
  font-size: 3rem;
  font-family: ${theme('fonts.libreBaskerville')};
  line-height: 1.2;
  letter-spacing: 1.25px;
  text-align: center;
  ${theme('media.smDown')} {
    font-size: 2rem;
  }
`

const ButtonStyled = styled(Button)`
  width: 16rem;
  margin-top: 3.5rem;
  text-align: center;
  ${theme('media.smDown')} {
    width: 100%;
  }
`

const PressContainer = styled(Container)`
  padding: 9rem 0 10rem 0;
  ${theme('media.smDown')} {
    padding: 7rem 0;
  }
`

const PressHeading = styled(Heading)`
  margin-bottom: 3.75rem;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 0.92;
  text-align: center;
`

const DotsSection = styled.div`
  position: relative;
  padding: 2rem 0 8rem 0;

  & p {
    margin: auto;
    color: ${palette('black', 1)};
    font-weight: 700;
    font-size: 3rem;
    font-family: ${theme('fonts.libreBaskerville')};
    line-height: 1.2;
    letter-spacing: 1.25px;
    text-align: center;

    & mark {
      color: ${palette('black', 1)};
    }
  }
`

const SectionAdvantagesContainer = styled(Container)`
  ${theme('media.smUp')} {
    margin-top: 7rem;
  }
`
const MainButton = styled(Button)`
  width: 100vw;
  margin-bottom: 0;
  margin-left: -3%;
  padding: 1.25rem;
  text-align: center;
  background-color: ${palette('red', 0)};
  &:not([disabled]):focus,
  &:not([disabled]):hover {
    background-color: ${palette('orange')};
  }
  ${theme('media.smUp')} {
    width: max-content;
    margin-bottom: 4.5rem;
  }
`
const HeaderContainer = styled.div`
  padding-top: 10rem;
  background-color: ${palette('sage', 0)};
  background-image: linear-gradient(to bottom, rgba(188, 208, 199, 0.52), ${palette('sage', 0)}),
    url(${background});
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100vw;

  ${theme('media.smUp')} {
    padding: 3rem 0 2rem 0;
    background-color: ${palette('sage', 0)};
    background-image: none;
  }
`

const FrontPage = ({
  data: {
    collections: { products },
    faqData: { entries },
  },
}) => (
  <Page
    title="Das flexible Abo für Kinderwagen & Kinderfahrräder"
    description="Miete einen Kinderwagen oder dein Kinderfahrrad ab 10€ im Monat und bleibe flexibel: Das Abo wächst mit deinem Kind mit und du bist immer mit der passenden Größe unterwegs. Es ist Zeit, Besitz neu zu denken und unsere Ressourcen zu schützen. Mach mit! Werde Teil der Community"
    backgroundColor={themeConf.palette.sage[0]}
  >
    <HeaderContainer>
      <Container>
        <Columns flipDesktop>
          <Column size={7}>
            <StaticImage
              src="../static/images/february-shooting/strollme_shooting_Feb_21-201.jpg"
              alt=""
              className="heroImg"
              layout="constrained"
              objectFit="cover"
              objectPosition="center center"
              placeholder="tracedSVG"
            />
          </Column>
          <Column size={5} align="flex-end">
            <MainHeadingStyled level={1}>
              Das flexible Abo für Kinderwagen & Kinderfahrräder
            </MainHeadingStyled>
            <ParagraphStyled size="large">
              Flexibel deinen&nbsp;
              <Mark
                image={markImages.sandNormal}
                style={{ backgroundPosition: `0px calc(100% + 0.5rem)` }}
              >Kinderwagen
              </Mark> oder&nbsp;
              <Mark
                image={markImages.sandNormal}
                style={{ backgroundPosition: `0px calc(100% + 0.5rem)` }}
              >Kinderfahrrad
              </Mark> ab 10€ pro Monat mieten. Sobald dein Kind herauswächst, wechsle auf
              das nächste Modell oder beende das Abo jederzeit.
              Das nachhaltige Abo-Modell schont Ressourcen, den Geldbeutel und schützt vor
              Überproduktion.
              <br /><br />
              Mach mit und denke Besitz neu!
            </ParagraphStyled>

            <MainButton to="/produkte">Zu den Kinderwagen &amp; Fahrrädern</MainButton>
          </Column>
        </Columns>
      </Container>
    </HeaderContainer>

    <SectionAdvantagesContainer>
      <SectionAdvantages />
    </SectionAdvantagesContainer>

    <StoneBg>
      <Container>
        <SectionProcess />
      </Container>
    </StoneBg>
    <DotsSection>
      <Container>
        <HeadingStyled level={2} style={{ textAlign: 'center' }}>
          Produkte, die wir selber lieben.
          <br />
          Für die Sicherheit der Kleinsten.
        </HeadingStyled>
      </Container>
      <Pattern top="40%" right="0" transformX="35%" zIndex="0">
        <SvgPatternSpots />
      </Pattern>
    </DotsSection>
    <MainProductList products={products} />

    <CircularEconomyBg>
      <SectionCircularEconomy />
    </CircularEconomyBg>

    <FAQContainer>
      <FAQHeading level={2}>
        Noch{' '}
        <Mark
          image={markImages.sandNormal}
          style={{ backgroundPosition: '0 100%', backgroundSize: '100%' }}
        >
          Fragen?
        </Mark>
      </FAQHeading>
      {
        // eslint-disable-next-line react/prop-types
      }
      {entries.map((item) => (
        <Collapsible
          key={item.frontmatter.title}
          title={item.frontmatter.title}
          htmlContent={item.html}
        />
      ))}
      <ButtonStyled to="/faq">Zu den FAQ</ButtonStyled>
    </FAQContainer>

    <StoneBg>
      <PressContainer>
        <PressHeading level={2}>Hier hast du von uns gehört</PressHeading>
        <Press />
      </PressContainer>
    </StoneBg>
  </Page>
)

FrontPage.propTypes = {
  data: PropTypes.shape({
    collections: PropTypes.shape({
      ...MainProductList.propTypes,
    }).isRequired,
    faqData: PropTypes.shape({
      entries: PropTypes.arrayOf(
        PropTypes.shape({
          frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired,
          }).isRequired,
          html: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

export default FrontPage

export const query = graphql`
  query Front($ids: [String!]!) {
    collections: allShopifyCollection(filter: { id: { in: $ids } }) {
      products: nodes {
        ...ProductPreview
      }
    }
    faqData: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//data/faq//" }, frontmatter: { front: { eq: true } } }
      sort: { fields: frontmatter___order }
    ) {
      entries: nodes {
        ...FAQEntry
      }
    }
  }
`
