import React from 'react'
import styled from 'styled-components'
import { theme, palette } from 'styled-tools'

import Heading from '../Heading'
import Paragraph from '../Paragraph'
import Button from '../Button'

import { ColumnViewMediaThreshold } from '../Column/styled'

export const Container = styled.div`
  margin: 8rem 0 5rem;
  padding: 6rem 0;

  ${theme(ColumnViewMediaThreshold)} {
    margin: 3rem 0 4rem;
  }
`

export const HeadingStyled = styled((props) => <Heading {...props} />)`
  color: ${palette('black', 1)};
  font-weight: 700;
  font-size: 3rem;
  font-family: ${theme('fonts.libreBaskerville')};
  line-height: 1.2;
  letter-spacing: 1.25px;

  ${theme('media.mdDown')} {
    font-size: 2rem;
  }
`

export const ParagraphStyled = styled((props) => <Paragraph {...props} />)`
  margin: 1.5rem 0 2rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 1.5;

  ${theme(ColumnViewMediaThreshold)} {
    margin: 0.5rem 0 2rem;
  }
`

export const ButtonStyled = styled((props) => <Button {...props} />)`
  padding: 1.25rem;
  font-size: 1rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 2.25;
  letter-spacing: 0.2px;
  white-space: nowrap;
`

export const LeftColumnContent = styled.div`
  margin-right: 2rem;

  ${theme(ColumnViewMediaThreshold)} {
    margin: unset;
    margin-bottom: 10rem;
  }
`

export const RightColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${theme(ColumnViewMediaThreshold)} {
    align-items: flex-start;
  }
`
