import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import Heading from '../Heading'

const tabletMedia = theme('media.lgDown')
const mobileMedia = theme('media.smDown')

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0 2rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.8px;
  text-align: center;

  ${theme('media.lgUp')} {
    padding-top: 6rem;
  }
`

export const HeadingStyled = styled((props) => <Heading {...props} />)`
  font-weight: bold;
  font-size: 1.75rem;
  font-family: ${theme('fonts.libreBaskerville')};
  line-height: 1.2;
  letter-spacing: 1.25px;

  ${theme('media.lgUp')} {
    font-size: 3rem;
  }
`

export const GridItemsContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);

  margin: auto;
  padding-bottom: 2rem;

  ${tabletMedia} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mobileMedia} {
    grid-template-columns: 1fr;
  }
`

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3.5rem 0;
  line-height: 1.5;
  text-align: center;

  ${tabletMedia} {
    margin: 2.5rem 0;
  }

  ${mobileMedia} {
    margin: 1.5rem;
  }
`

export const GridItemHeadingContainer = styled.div`
  margin: 1rem 0 1.5rem;
  font-weight: bold;
`

export const GridItemIconContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 4.125rem;
`
