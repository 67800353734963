import React from 'react'
import PropTypes from 'prop-types'

import {
  ListItemContainer,
  ListHeaderContainer,
  ListHeadingStyled,
  ListParagraphStyled,
  ListCounter,
  YellowListShapeImageStyled,
} from './styled'

const ListItem = ({ counter, header, description }) => (
  <ListItemContainer>
    <ListHeaderContainer>
      <YellowListShapeImageStyled />
      <ListCounter>{counter}</ListCounter>

      <ListHeadingStyled level={3}>{header}</ListHeadingStyled>
    </ListHeaderContainer>

    <ListParagraphStyled>{description}</ListParagraphStyled>
  </ListItemContainer>
)

ListItem.propTypes = {
  counter: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default ListItem
