import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import YellowListShapeImage from '../../../../static/images/icons/yellow-list-shape.svg'

import Heading from '../../Heading'
import Paragraph from '../../Paragraph'

import { ColumnViewMediaThreshold } from '../../Column/styled'

export const ListItemContainer = styled.div`
  max-width: 25rem;
  margin-bottom: 7rem;

  :last-child {
    margin-bottom: unset;
  }
`

export const ListHeaderContainer = styled.div`
  position: relative;
  left: -3.5rem;
  display: flex;

  ${theme(ColumnViewMediaThreshold)} {
    left: 1rem;
  }
`

export const ListHeadingStyled = styled((props) => <Heading {...props} />)`
  position: relative;
  z-index: 1;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: ${theme('fonts.libreBaskerville')};
  line-height: 2.5;
  letter-spacing: 0.6px;
`

export const ListParagraphStyled = styled((props) => <Paragraph {...props} />)`
  position: relative;
  z-index: 1;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 1.5;

  ${theme(ColumnViewMediaThreshold)} {
    margin-left: 4.5rem;
  }
`

export const ListCounter = styled.span`
  position: relative;
  z-index: 1;
  display: block;
  font-weight: bold;
  font-size: 7.5rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 0;

  ${theme('media.mdDown')} {
    font-size: 7rem;
  }
`

export const YellowListShapeImageStyled = styled((props) => <YellowListShapeImage {...props} />)`
  position: absolute;
  left: -1.5rem;
`
